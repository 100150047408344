import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { getComapnyProfile } from "../../../store/api/apiSlice";
import { useAppDispatch } from "../../../store/hooks";

import { useLocation } from "react-router-dom";
import ReportIssue from "./reportIssue";
import PortalTraning from "./portalTraning";
import GlobalPopup from "../GlobalPopup/globalPopup";
import { hideLoader, showLoader } from "../GlobalLoader";
import axios from "../../../../src/services/httpService";
import getToken from "../../../../src/store/api/api";
import { usePermission } from "../../../helpers/hooks/usePermissions";

export const Help = () => {
  const API_URL = axios.Api;
  const USERMANAGEAPI = axios.UserManageAPI || "";
  const permissions = usePermission(true);
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [portalShow, setPortalShow] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [refeshData, setRefreshData] = useState(false);
  const portalClose = () => {
    setPortalShow(false);
    setRefreshData(true);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSuccessClose = () => setSuccessShow(false);
  const dispatch = useAppDispatch();
  const [data, setData]: any = useState();

  useEffect(() => {
    dispatch(getComapnyProfile()).then((result: any) => {
      setData(result?.payload?.data);
    });
  }, [refeshData]);

  useEffect(() => {
    if (location.pathname === "/support/reportIssue") {
      setShow(true);
    }
    if (location.pathname === "/support/portalTraning") {
      setPortalShow(true);
    }
  }, []);

  const downloadPDFClick = async (type = "") => {
    showLoader();
    try {
      const azureB2C = localStorage.getItem("AzureB2CImpersonate");
      let fixUrl = "";

      if (type === "user_guide")
        fixUrl =
          API_URL +
          USERMANAGEAPI +
          `Company/DownloadHelpPDFFile?fileName=` +
          data?.helpDocumentBlobUrl;
      else if (type === "faq")
        fixUrl = API_URL + USERMANAGEAPI + `Company/DownloadFAQFile`;
      else if (type === "report_desc_glos")
        fixUrl =
          API_URL +
          USERMANAGEAPI +
          `Company//DownloadReportDescriptionsAndGlossaryFile`;
      else if (type === "print_label_guide")
        fixUrl =
          API_URL + USERMANAGEAPI + `Company/DownloadPrintLabelGuideFile`;

      fetch(fixUrl, {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken()),
          ...((azureB2C && { UserImpersonated: azureB2C }) || {}),
        },
      })
        .then(async (response: any) => {
          const b = await response.blob();
          let url = URL.createObjectURL(b);
          let a = document.createElement("a");
          a.href = url;
          a.target = "_blank";
          a.click();
        })
        .catch((err) => console.log(err))
        .finally(() => {
          hideLoader();
        });
    } catch (error) {
      hideLoader();
    }
  };

  return (
    <>
      <Container fluid>
        <div className="content_header d-flex justify-content-between align-items-center">
          <div className="header_left">
            <h5 className="content_header_title">Support and Training</h5>
          </div>

          <div className="header_right">
            {permissions?.["HelpReportAnIssue"] && (
              <Button variant="primary" onClick={handleShow}>
                <i className="fa-solid fa-circle-exclamation me-1"></i> Report
                Issue
              </Button>
            )}
            <ReportIssue
              show={show}
              handleClose={handleClose}
              setShow={setShow}
              setSuccessShow={setSuccessShow}
            ></ReportIssue>
            <PortalTraning
              show={portalShow}
              handleClose={portalClose}
            ></PortalTraning>
            <GlobalPopup
              show={successShow}
              showImage={true}
              handleClose={(e: any) => handleSuccessClose()}
              doneButton={true}
              message={
                "Your issue has been sent successfully. customer support team will contact you shortly."
              }
            ></GlobalPopup>
          </div>
        </div>

        <div className="content_body">
          <div className="help-wrapper">
            <Row className="gx-3">
              <Col xs={12} md={6} xl={4}>
                <div className="box">
                  <h5>Contact Us</h5>
                  <div className="d-flex align-items-center gap-2 mb-2 detail">
                    <div>
                      <i className="fa-solid fa-phone"></i>
                    </div>
                    <div>
                      <a href={data?.contactPhone}>{data?.contactPhone}</a>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 detail mb-2">
                    <div>
                      <i className="fa-solid fa-envelope"></i>
                    </div>
                    <div>
                      <a href="mailto:CustomerAdvTeam@pharmalogistics.com">
                        {data?.contactEmail}
                      </a>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2 detail">
                    <div>
                      <i className="fa-solid fa-location-dot"></i>
                    </div>
                    <div>{data?.companyFullAddress}</div>
                  </div>
                  <div className="d-flex align-items-center gap-2 detail">
                    <div>
                      <i className="fa-solid fa-clock"></i>
                    </div>
                    <div>{data?.businessHours}</div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="gx-3">
              <Col xs={12} md={6} xl={4}>
                <div className="box">
                  <h5>Training Resources</h5>
                  <div className="box">
                    <h3>User Guide</h3>
                    <div className="d-flex align-items-center gap-3 detail">
                      <div className="icon-pdf">
                        <i className="fa-regular fa-file-pdf"></i>
                      </div>
                      <div>
                        <a
                          // href={data?.helpDocumentBlobUrl}
                          // variant="link"
                          href="#"
                          className="text-link"
                          onClick={() => {
                            downloadPDFClick("user_guide");
                          }}
                        >
                          <i className="fa-solid fa-file-download me-2"></i>
                          Download PDF
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="box">
                    <h3>FAQ</h3>
                    <div className="d-flex align-items-center gap-3 detail">
                      <div className="icon-pdf">
                        <i className="fa-regular fa-file-pdf"></i>
                      </div>
                      <div>
                        <a
                          href="#"
                          className="text-link"
                          onClick={() => {
                            downloadPDFClick("faq");
                          }}
                        >
                          <i className="fa-solid fa-file-download me-2"></i>
                          Download PDF
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="box">
                    <h3>Report Descriptions and Glossary</h3>
                    <div className="d-flex align-items-center gap-3 detail">
                      <div className="icon-pdf">
                        <i className="fa-regular fa-file-pdf"></i>
                      </div>
                      <div>
                        <a
                          href="#"
                          className="text-link"
                          onClick={() => {
                            downloadPDFClick("report_desc_glos");
                          }}
                        >
                          <i className="fa-solid fa-file-download me-2"></i>
                          Download PDF
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="box">
                    <h3>Print Label Guide</h3>
                    <div className="d-flex align-items-center gap-3 detail">
                      <div className="icon-pdf">
                        <i className="fa-regular fa-file-pdf"></i>
                      </div>
                      <div>
                        <a
                          href="#"
                          className="text-link"
                          onClick={() => {
                            downloadPDFClick("print_label_guide");
                          }}
                        >
                          <i className="fa-solid fa-file-download me-2"></i>
                          Download PDF
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="box">
                    <h3>Demo Video</h3>
                    <div
                      style={{ padding: "56.25% 0 0 0", position: "relative" }}
                    >
                      <iframe
                        src="https://player.vimeo.com/video/995112918?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        allow="autoplay; fullscreen; picture-in-picture "
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",
                        }}
                        title="Pharma Logistics Customer Portal Demo"
                      ></iframe>
                    </div>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Help;
