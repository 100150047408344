import React, { useCallback, useEffect, useState, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import { useAppDispatch } from "../../../../store/hooks";
import { putReturnOrder } from "../../../../store/api/apiSlice";
import NewNdcSuccessModal from "./newNdcSuccessModal";
import { useNavigate } from "react-router-dom";
import GridClientComponent from "../../../../components/grid/gridClientSide";
import { toast } from "react-toastify";
import UPSLabelswoInventoryPopup from "../../../../components/service/upsLabelswoInventory";
import { StartReturnContext } from "../../../../context/StartReturnContext";
import PrintLabelMsgModal from "../../../../pages/admin/serviceModule/startReturn/printLabelMsgModal";

function SaveModal(props: any) {
  const { supplyTypeSelected, updateStartRetrunContextData, printLabel } =
    useContext(StartReturnContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const status_id = { Draft: 1, Pending: 2 };
  const [ndSuccessShow, setNdSuccessShow] = useState(false);
  const [isUPSwoInventoryModal, setIsUPSwoInventoryModal] = useState(false);
  const [disposalAccountSelectedObj, setDisposalAccountSelectedObj] = useState(
    {}
  );
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    sortField: "",
  });
  const ndcSucessClose = () => {
    if (
      props?.data?.entryMode === 1 &&
      !props?.data?.shippingLabelQuantity &&
      !props?.data?.tamperEvidentBagQuantity
    ) {
      setIsUPSwoInventoryModal(true);
      setNdSuccessShow(false);
    } else {
      navigate("/services/viewHistory");
      setNdSuccessShow(false);
    }
  };
  const class2Data: any = [];
  props?.data?.products?.filter((e: any) => {
    if (e.deaClass === 2) {
      class2Data.push(e.deaClass);
    }
  });

  const classOtherData: any = [];
  props?.data?.products?.filter((e: any) => {
    if (e.deaClass === 3 || e.deaClass === 4 || e.deaClass === 5) {
      classOtherData.push(e.deaClass);
    }
  });

  const nonControlData: any = [];
  props?.data?.products?.filter((e: any) => {
    if (e.deaClass === 0 || e.deaClass === 1) {
      nonControlData.push(e.deaClass);
    }
  });

  const columnitems = [
    {
      headerName: "BNS ACCOUNT",
      field: "bnsAccountId",
      tooltipField: "bnsAccountName",
      headerTooltip: "BNS ACCOUNT",
      tooltipShowDelay: 0,
      valueFormatter: function (params: any) {
        return params.data.bnsAccountName;
      },
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      headerName: "QTY. OF SHIPPING LABELS",
      headerTooltip: "QTY. OF SHIPPING LABELS",
      tooltipShowDelay: 0,
      field: "shippingLabelQuantity",
      suppressMenu: true,
      valueFormatter: function (params: any) {
        if (params.value === null) {
          return "-";
        } else {
          return params.value;
        }
      },
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      headerName: "QTY. OF TAMPER-EVIDENT BAGS",
      headerTooltip: "QTY. OF TAMPER-EVIDENT BAGS",
      tooltipShowDelay: 0,
      field: "tamperEvidentBagQuantity",
      suppressMenu: true,
      valueFormatter: function (params: any) {
        if (params.value === null) {
          return "-";
        } else {
          return params.value;
        }
      },
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      headerName: "CII-INVENTORY",
      headerTooltip: "CII-INVENTORY",
      tooltipShowDelay: 0,
      field: "",
      flex: 1,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      valueFormatter: function (params: any) {
        return class2Data ? class2Data.length : "";
      },
    },
    {
      headerName: "CIII-V INVENTORY",
      headerTooltip: "CIII-V INVENTORY",
      tooltipShowDelay: 0,
      field: "",
      flex: 1,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      valueFormatter: function (params: any) {
        return classOtherData ? classOtherData.length : "";
      },
    },
    {
      headerName: "Non-Control Inventory",
      headerTooltip: "Non-Control Inventory",
      tooltipShowDelay: 0,
      field: "",
      flex: 1,
      floatingFilterComponentParams: { suppressFilterButton: true },
      valueFormatter: function (params: any) {
        return nonControlData ? nonControlData.length : "";
      },
    },
    {
      headerName: "TOTAL INVENTORY",
      headerTooltip: "TOTAL INVENTORY",
      tooltipShowDelay: 0,
      field: "",
      flex: 1,
      valueFormatter: function (params: any) {
        return params.data.products.length;
      },
    },
  ];

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const onGridReady = useCallback(() => {
    return updateGridData("rows", [props?.data]);
  }, []);

  useEffect(() => {
    updateGridData("totalRows", props?.data?.length);
  }, []);

  const yesButtonClick = () => {
    // PUT API call with Status 2
    props.data["statusId"] = status_id.Pending;
    props.handleClose();
    props.data.products.forEach((element: any) => {
      if (element["quantity"] === "") {
        element["quantity"] = element["quantity1"];
        delete element["quantity1"];
      }
    });
    dispatch(putReturnOrder(props.data)).then((result: any) => {
      if (result?.payload?.isSuccess) {
        if (props?.entryMode === 3) {
          toast.success("Saved Succesfully");
          navigate("/services/viewHistory");
        } else {
          if (printLabel) {
            setDisposalAccountSelectedObj(
              result?.payload?.data?.accountDetail || {}
            );
            setNdSuccessShow(false);
            setIsUPSwoInventoryModal(true);
          } else {
            setNdSuccessShow(true);
          }
        }
      }
    });
  };

  let preventNavigate = false;

  const handleClosePrintLabels = () => {
    setIsUPSwoInventoryModal(false);
    setDisposalAccountSelectedObj({});
    if (!preventNavigate) {
      navigate("/services/viewHistory");
    }
  };

  const LabelErrorsModalHide = () => {
    preventNavigate = false;
    setLabelErrorsModalVisible(false);
    navigate("/services/viewHistory");
  };

  const [labelErrorsModalVisible, setLabelErrorsModalVisible] = useState(false);
  const handlePrintLabelError = (errorMessage: string) => {
    setLabelErrorsModalVisible(true);
    preventNavigate = true;
  };

  return (
    <>
      <PrintLabelMsgModal
        show={labelErrorsModalVisible}
        handleClose={LabelErrorsModalHide}
      ></PrintLabelMsgModal>

      {isUPSwoInventoryModal && (
        <UPSLabelswoInventoryPopup
          startReturn={true}
          disposalAccount={disposalAccountSelectedObj}
          show={isUPSwoInventoryModal}
          handleClose={handleClosePrintLabels}
          onError={handlePrintLabelError}
        ></UPSLabelswoInventoryPopup>
      )}

      <NewNdcSuccessModal
        show={ndSuccessShow}
        handleClose={ndcSucessClose}
        message1={"You will receive your returns kit in 3-5 business days."}
        message2={"Please return inventoried product in received kits."}
      ></NewNdcSuccessModal>

      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="custom_modal"
        dialogClassName="modal-90w start-return-confirm"
      >
        <Modal.Body className="custom_modal_body">
          <p className="mb-0">
            Are you sure you want to proceed with below details?
          </p>
          <GridClientComponent
            rowData={[props.data]}
            colDefs={columnitems}
            onGridReady={onGridReady}
            gridData={gridData}
            setGridData={setGridData}
            hideheader={true}
            isModalGrid={true}
            autoSizeStrategy={{
              type: "fitCellContents",
              colIds: ["shippingLabelQuantity", "tamperEvidentBagQuantity"],
            }}
          ></GridClientComponent>
        </Modal.Body>
        <Modal.Footer className="custom_modal_footer">
          <Button variant="primary" onClick={yesButtonClick}>
            Yes
          </Button>
          <Button variant="secondary" onClick={props.handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SaveModal;
