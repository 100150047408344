import React, {
  Fragment,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useContext,
} from "react";
import { Button, Col, Form, Row, Tooltip } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useAppDispatch } from "../../../../store/hooks";
import {
  getDisposalAccount,
  getProductDetailByNdc,
  getReturnOrder,
  postReturnOrder,
  putReturnOrder,
  postNewNdc,
  checkCustomerLicence,
} from "../../../../store/api/apiSlice";
import NDCDetails from "./ndcDetails";
import NdcDetailsModal from "./ndcDetailsModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { toast } from "react-toastify";
import NewNdcSuccessModal from "./newNdcSuccessModal";
import { useSelector } from "react-redux";
import {
  DISABLED_STATES_FOR_SHIP,
  formateIds,
} from "../../../../interface/comman.constant";
import { StartReturnContext } from "../../../../context/StartReturnContext";

const StartReturnHeader = forwardRef((props: any, ref: any) => {
  const options_value: any = [
    { value: "1", label: "Start a Return - No CII's" },
    { value: "2", label: "Start a Return - Includes CII's" },
  ];
  const options_value1: any = [{ value: "3", label: "Inventory Only" }];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const status_id = { Draft: 1, Pending: 2 };

  const dispatch = useAppDispatch();
  const [disposalAccountData, setDisposalAccountData] = useState([]);
  const [entryMode, setEntryMode] = useState("");
  const [quantity, setQuantity] = useState(false);
  const [ndcValue, setNdcValue] = useState("");
  const [ndcPreviousValue, setNdcPreviousValue] = useState("");
  const [ndcDetailsData, setNdcDetailsData]: any = useState({});
  const [ndcError, setNdcError] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [valid, setValid] = useState(true);
  const [bnsAccountId, setbnsAccountId] = useState("");
  const [shippingLabelQuantity, setShippingLabelQuantity] = useState("");
  const [tamperEvidentBagQuantity, setTamperEvidentBagQuantity] = useState("");
  const [gs1SerialNumber, setGs1SerialNumber] = useState("");
  const [lotNumber, setLotNumber] = useState("");
  const [quantityData, setQuantityData] = useState("");
  const [errorNDCMessage, setErrorNDCMessage] = useState("");
  const [errorQuantityMessage, setErrorQuantityMessage] = useState("");
  const [errorBNSMessage, setErrorBNSMessage] = useState("");
  const [errorEntryModeMessage, setErrorEntryModeMessage] = useState("");
  const [responseData, setResponseData]: any = useState();
  const [bnsAccountName, setbnsAccountName] = useState("");
  const [bnsSelectionError, setbnsSelectionError] = useState("");
  const [ndSuccessShow, setNdSuccessShow] = useState(false);
  const [errorShippingLable, setErrorShippingLable] = useState("");
  const [errorTamperBag, setErrorTamperBag] = useState("");
  const [DEALicence, setDEALicence]: any = useState();
  const [quantityGreaterMessage, setQuantityGreaterMessage] = useState("");
  const [errorLotMessage, setErrorLotMessage] = useState("");
  const [errorDateMessage, setErrorDateMessage] = useState("");
  const [packageSize, setPackageSize] = useState("");
  const [isInventory, setIsInventory] = useState<any>();

  const [selectedRadio, setSelectedRadio] = useState("YES");
  const [supplyTypeRadio, setSupplyTypeRadio] = useState("BNS Kit");
  const [isAccountDisabled, setIsAccountDisabled] = useState(false);

  const disposal_options: any = [];
  const navigate = useNavigate();
  const location = useLocation();
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  let tmp = location.pathname.slice(
    location.pathname.lastIndexOf("/") + 1,
    location.pathname.length
  );

  const { supplyTypeSelected, updateStartRetrunContextData, printLabel } =
    useContext(StartReturnContext);

  const isInputDisabled =
    printLabel || entryMode === "3" || tmp !== "start-Inventoried-return";

  const handleImportInventoryClick = () => {
    if (tmp === "start-Inventoried-return") {
      if (
        inventoryValidate() &&
        errorEntryModeMessage === "" &&
        errorBNSMessage === "" &&
        errorShippingLable === "" &&
        errorTamperBag === ""
      ) {
        try {
          const data = {
            //  Entry Mode
            // 1 - Basic
            // 2 - Advanced
            entryMode: Number(entryMode),
            bnsAccountId: bnsAccountId,
            bnsAccountName: bnsAccountName,
            shippingLabelQuantity: shippingLabelQuantity
              ? Number(shippingLabelQuantity)
              : null,
            tamperEvidentBagQuantity: tamperEvidentBagQuantity
              ? Number(tamperEvidentBagQuantity)
              : null,
            products: [],
            //status id
            //Draft = 1,
            // Pending = 2
            statusId: status_id.Draft,
          };

          //post
          dispatch(postReturnOrder(data)).then((result: any) => {
            setNdcPreviousValue("");
            if (result.payload.data) {
              navigate(
                "/services/start-Inventoried-return/" + result.payload.data.id
              );
              props.handleShow(result.payload.data.id);
            }
          });
        } catch (error) {}
      }
    } else {
      props.handleShow(tmp);
    }
  };
  useImperativeHandle(ref, () => ({
    handleImportInventoryClick,
  }));

  const inventoryValidate = () => {
    let isValid = true;

    if (!entryMode) {
      setErrorEntryModeMessage("Entry Mode is required");
      isValid = false;
    }

    if (!bnsAccountId) {
      setErrorBNSMessage("Account is required");
      isValid = false;
    }

    if (entryMode !== "3" && !(supplyTypeRadio === "Print Labels")) {
      if (!shippingLabelQuantity) {
        setErrorShippingLable(
          "Quantity of Shipping Labels should be minimum 1."
        );
        isValid = false;
      }

      if (!tamperEvidentBagQuantity) {
        setErrorTamperBag(
          "Quantity of Tamper Evident bags should be minimum 1."
        );
        isValid = false;
      }
    }

    return isValid;
  };

  disposalAccountData &&
    disposalAccountData.map((item: any) =>
      disposal_options.push({
        value: item.id,
        label: item.customerCode + "-" + item.disposalAccountName,
        isInventoryOnly: item.isInventoryOnly,
        stateAbbreviation: item.shippinG_STATE_ABBREVIATION,
      })
    );

  const entryModeChange = (e: any) => {
    const newValue = e.value;
    if (newValue === entryMode) return;
    setEntryMode(e.value);
    setErrorEntryModeMessage("");
    setSelectedRadio("");
    setSupplyTypeRadio("");
    updateStartRetrunContextData("");

    if (e.value === "1") {
      setSelectedRadio("YES");
      setSupplyTypeRadio("BNS Kit");
      updateStartRetrunContextData("BNS Kit");
    }

    if (e.value === "2" && errorNDCMessage !== "") {
      setErrorNDCMessage("");
    }
    if (
      (e.value === "3" || supplyTypeRadio === "Print Labels") &&
      errorShippingLable !== "" &&
      errorTamperBag !== ""
    ) {
      setErrorShippingLable("");
      setErrorTamperBag("");
    }
  };
  const getDropDownData = () => {
    try {
      let data = 1;
      dispatch(
        getDisposalAccount({
          data: data,
          customerIds: formateIds(userAccounts),
        })
      ).then((result: any) => {
        setDisposalAccountData(result?.payload?.data);
      });
    } catch (error) {}
  };

  useEffect(() => {
    getDropDownData();
    getGridData(tmp);
  }, [refreshPage]);

  const bnsAccountChange = (e: any) => {
    setbnsSelectionError("");
    setErrorBNSMessage("");
    setbnsAccountName(e.label);
    setbnsAccountId(e.value);
    setIsInventory(e.isInventoryOnly);
    setEntryMode("");

    if (
      e?.stateAbbreviation &&
      DISABLED_STATES_FOR_SHIP.includes(e.stateAbbreviation)
    ) {
      setIsAccountDisabled(true);
    } else if (isAccountDisabled) {
      setIsAccountDisabled(false);
    }

    //API call for Return Order Access
    try {
      dispatch(checkCustomerLicence(e.value)).then((result: any) => {
        if (result?.payload?.data?.returnOrderAccess === false) {
          setErrorBNSMessage(
            "Start a Return Order is not accessible for the selected BNS Account"
          );
          setDEALicence(false);
          props.DEALicence(false);
        } else {
          setDEALicence(true);
          props.DEALicence(true);
        }
      });
    } catch (error) {}
  };

  const switchChange = (e: any) => {
    setQuantity(e.target.checked);
    setNdcDetailsDataQuantityValidation(quantityData, e.target.checked);
  };

  const onKeyDownHandler = (e: any) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
    const tmpNdcValue: any = ["e", "E", ".", "+", "-"];
    if (tmpNdcValue.includes(e.key) && e.preventDefault()) {
      setErrorNDCMessage("NDC number should be digit only");
    } else {
      const inputValue = e.target.value;
      if (inputValue.length > 11) {
        setNdcError(false);
        setErrorNDCMessage("NDC number length should not be greater than 11");
      } else if (inputValue.length < 10) {
        setNdcError(false);
        setErrorNDCMessage("NDC number should be of 10 or 11 digit");
      } else if (
        e.target.value.length > 9 &&
        e.target.value.length < 12 &&
        (e.keyCode === 13 || e.keyCode === 9)
      ) {
        setNdcError(false);
        setErrorNDCMessage("");
        getNDCDetails();
      } else if (e.target.value.length > 9 && e.target.value.length < 12) {
        setNdcError(false);
        setErrorNDCMessage("");
      } else if (DEALicence === false) {
        setErrorNDCMessage(
          "The DEA license is expired for the selected account"
        );
      }
    }
  };

  const onNDCPaste = () => {
    setErrorNDCMessage("");
  };

  const NdcValueChange = (e: any) => {
    const inputValue = e.target.value;
    setNdcValue(e.target.value);
  };
  const NdcValueBlur = (e: any) => {
    if (DEALicence === false) {
      setErrorNDCMessage("The DEA license is expired for the selected account");
    }
    if (ndcValue.length > 9 && ndcValue.length < 12 && ndcError === false) {
      setNdcError(false);
      setErrorNDCMessage("");
      getNDCDetails();
    }
  };
  const handleClose = () => setShow(false);
  const ndcSucessClose = () => setNdSuccessShow(false);

  const handleSave = (e: any, data: any) => {
    e.preventDefault();

    if (data.deaClass === null || data.ndc === "" || data.product === "") {
      setbnsSelectionError("NDC Details form fields should not be empty");
    } else {
      if (bnsAccountId && bnsAccountName) {
        //API call
        data.bnsAccountId = bnsAccountId;
        data.bnsAccountName = bnsAccountName;
        data.deaClass = Number(data.deaClass);

        dispatch(postNewNdc(data)).then((result: any) => {
          if (result.payload.isSuccess) {
            //show popup
            setNdSuccessShow(true);
            setShow(false);
          }
        });
      } else {
        setbnsSelectionError("Select BNS Account to save ndc details");
      }
    }
  };

  const handleDateChange = (e: any) => {
    setSelectedDate(e);
    document?.getElementById("gs1serial")?.focus();
  };

  const validate = () => {
    if (ndcValue === "") {
      setErrorNDCMessage("NDC is required");
    } else if (ndcValue !== "" && ndcError === false) {
      if (ndcValue.length < 10 || ndcValue.length > 11)
        setErrorNDCMessage("NDC number should be of 10 or 11 digit");
      else if (Object?.keys(ndcDetailsData).length === 0)
        setErrorNDCMessage(
          "NDC details should not be empty. Press enter to get NDC details."
        );
    }
    if (entryMode === "") {
      setErrorEntryModeMessage("EntryMode is required");
    }
    if (bnsAccountId === "") {
      setErrorBNSMessage("BNS Account is required");
    }
    if (quantityData === "") {
      setErrorQuantityMessage("Quantity is required");
    }

    if (parseInt(quantityData) < 1) {
      setErrorQuantityMessage("Quantity should be greater than 0");
    }

    if (entryMode === "2") {
      if (lotNumber === "") {
        setErrorLotMessage("Lot is required");
      }
      if (!selectedDate) {
        setErrorDateMessage("Expiration date is required");
      }
    }
    if (entryMode !== "3" && !(supplyTypeRadio === "Print Labels")) {
      if (shippingLabelQuantity === "" || Number(shippingLabelQuantity) === 0) {
        setErrorShippingLable(
          "Quantity of Shipping Labels should be minimum 1."
        );
      }
      if (
        tamperEvidentBagQuantity === "" ||
        Number(tamperEvidentBagQuantity) === 0
      ) {
        setErrorTamperBag(
          "Quantity of Tamper Evident bags should be minimum 1."
        );
      }
    } else {
      return true;
    }
  };

  const setNdcDetailsDataQuantityValidation = (
    newfullqty: any,
    fullorpartial: any
  ) => {
    setQuantityData(newfullqty);

    let newValidStr = "";

    //partial
    if (fullorpartial && newfullqty) {
      if (ndcDetailsData?.casePack > 1)
        newValidStr =
          newfullqty === ""
            ? ""
            : newfullqty + " " + ndcDetailsData?.packageDescription;
      else
        newValidStr =
          newfullqty === ""
            ? ""
            : newfullqty + " " + ndcDetailsData?.dosageForm;
    }
    //full
    if (!fullorpartial && newfullqty) {
      newValidStr =
        parseInt(newfullqty) * ndcDetailsData?.casePack +
        " " +
        ndcDetailsData?.packageDescription;
    }

    const currentv = ndcDetailsData;
    setNdcDetailsData({ ...currentv, quantityValidation: newValidStr });
  };

  const addButtonClick = (e: any) => {
    e.preventDefault();
    validate();

    if (
      quantityData === "" ||
      quantityData === null ||
      parseInt(quantityData) < 1 ||
      ndcValue === null ||
      ndcValue === "" ||
      (ndcValue || "").length < 10 ||
      (ndcValue || "").length > 11 ||
      Object.keys(ndcDetailsData || {}).length === 0
    ) {
      return;
    }
    if (entryMode !== "3" && !(supplyTypeRadio === "Print Labels")) {
      if (
        shippingLabelQuantity === "" ||
        shippingLabelQuantity === "0" ||
        tamperEvidentBagQuantity === "" ||
        tamperEvidentBagQuantity === "0"
      ) {
        return;
      }
    }
    if (entryMode === "2") {
      if (lotNumber === "" || !selectedDate) {
        return;
      }
    }
    if (entryMode === "1" && ndcDetailsData.deaClass === 2) {
      setErrorNDCMessage("DEA Class 2 NDC not allowed for selected entry mode");
      return;
    }
    if (
      errorNDCMessage === "" &&
      errorQuantityMessage === "" &&
      errorBNSMessage === "" &&
      errorEntryModeMessage === "" &&
      errorShippingLable === "" &&
      errorTamperBag === "" &&
      ndcError === false
    ) {
      let momentDate = selectedDate ? moment(selectedDate).utc(true) : null;

      if (tmp !== "start-Inventoried-return") {
        try {
          const data = responseData;
          const newProduct = {
            ndc: ndcDetailsData?.ndc?.toString(),
            quantityType: quantity === false ? 2 : 1,
            quantity: Number(quantityData),
            lotNumber: lotNumber,
            expirationDate: momentDate ? momentDate?.format("MM-DD-YYYY") : "",
            gs1SerialNumber: gs1SerialNumber,
            productName: ndcDetailsData?.product,
            deaClass: ndcDetailsData?.deaClass,
            caseAndPackageSize: ndcDetailsData?.caseAndPackageSize,
            quantityValidation: ndcDetailsData?.quantityValidation,
            isInnerNdc: ndcDetailsData?.isInnerNdc,
            isNew: true,
          };
          data?.products.push(newProduct);
          data["statusId"] = status_id.Draft;
          // put;
          data.products.forEach((element: any) => {
            if (element["quantity"] === "") {
              element["quantity"] = element["quantity1"];
              delete element["quantity1"];
            }
            if (
              element["isNew"] &&
              ndcDetailsData?.casePack > 1 &&
              newProduct["quantityType"] === 1 &&
              ndcDetailsData?.casePack === newProduct["quantity"]
            ) {
              element["quantity"] = 1;
              element["quantityType"] = 2;
              delete element["isNew"];
            } else if (
              element["isNew"] &&
              ndcDetailsData?.casePack === 1 &&
              newProduct["quantityType"] === 1 &&
              ndcDetailsData?.packageSize === newProduct["quantity"]
            ) {
              element["quantity"] = 1;
              element["quantityType"] = 2;
              delete element["isNew"];
            }
          });
          dispatch(putReturnOrder(data)).then((result: any) => {
            setNdcPreviousValue("");
            if (result?.payload?.isSuccess) {
              toast.success("Saved Successfully");
              getGridData(responseData.id);
              resetData();
            }
          });
        } catch (error) {}
      } else {
        try {
          const data = {
            //  Entry Mode
            // 1 - Basic
            // 2 - Advanced
            entryMode: Number(entryMode),
            bnsAccountId: bnsAccountId,
            bnsAccountName: bnsAccountName,
            shippingLabelQuantity: shippingLabelQuantity
              ? Number(shippingLabelQuantity)
              : null,
            tamperEvidentBagQuantity: tamperEvidentBagQuantity
              ? Number(tamperEvidentBagQuantity)
              : null,
            products: [
              {
                ndc: ndcDetailsData?.ndc?.toString(),
                //QuantityType
                // 1 - Partial
                // 2 - Full
                quantityType: quantity === false ? 2 : 1,
                quantity: Number(quantityData),
                lotNumber: lotNumber,
                expirationDate: momentDate ? momentDate?.toISOString() : "",
                gs1SerialNumber: gs1SerialNumber,
                productName: ndcDetailsData?.product,
                deaClass: ndcDetailsData?.deaClass,
                caseAndPackageSize: ndcDetailsData?.caseAndPackageSize,
                quantityValidation: ndcDetailsData?.quantityValidation,
                isInnerNdc: ndcDetailsData?.isInnerNdc,
              },
            ],
            //status id
            //Draft = 1,
            // Pending = 2
            statusId: status_id.Draft,
            supplytype: supplyTypeRadio,
            bagoption: selectedRadio,
          };

          if (
            ndcDetailsData?.casePack > 1 &&
            data.products[0]["quantityType"] === 1 &&
            ndcDetailsData?.casePack === data.products[0]["quantity"]
          ) {
            data.products[0]["quantity"] = 1;
            data.products[0]["quantityType"] = 2;
          } else if (
            ndcDetailsData?.casePack === 1 &&
            data.products[0]["quantityType"] === 1 &&
            ndcDetailsData?.packageSize === data.products[0]["quantity"]
          ) {
            data.products[0]["quantity"] = 1;
            data.products[0]["quantityType"] = 2;
          }
          //post
          dispatch(postReturnOrder(data)).then((result: any) => {
            if (result?.payload?.data) {
              toast.success("Saved Successfully");
              navigate(
                "/services/start-Inventoried-return/" + result.payload.data.id
              );
              getGridData(result.payload.data.id);
              setQuantityData("");
              setLotNumber("");
              setSelectedDate(undefined);
              setQuantity(false);
              setGs1SerialNumber("");
              setNdcValue("");
              document?.getElementById("ndc")?.focus();
            }
          });
        } catch (error) {}
      }
    }
  };
  const resetData = () => {
    setNdcDetailsData("");
    setNdcError(false);
    setNdcPreviousValue("");
    setNdcValue("");
    setQuantityData("");
    setQuantity(false);
    setLotNumber("");
    setSelectedDate(undefined);
    setGs1SerialNumber("");
    document?.getElementById("ndc")?.focus();
  };

  const getGridData = (id: any) => {
    if (id !== "start-Inventoried-return") {
      try {
        dispatch(getReturnOrder(id)).then((result: any) => {
          props.gridDataResult(result?.payload?.data);
          setResponseData(result?.payload?.data);
          setShippingLabelQuantity(result?.payload?.data.shippingLabelQuantity);
          setTamperEvidentBagQuantity(
            result?.payload?.data.tamperEvidentBagQuantity
          );
          setbnsAccountId(result?.payload?.data.bnsAccountId);
          setbnsAccountName(result?.payload?.data.bnsAccountName);
          setEntryMode(result?.payload?.data.entryMode.toString());
          if (result?.payload?.data.entryMode === 3) setIsInventory(true);
          setDEALicence(true);
          props.DEALicence(true);
          setNdcDetailsData({});
          setSupplyTypeRadio(result?.payload?.data.supplyType);
          updateStartRetrunContextData(result?.payload?.data?.supplyType || "");
          setSelectedRadio(result?.payload?.data.bagOption);
        });
      } catch (error) {}
    }
  };

  const getNDCDetails = () => {
    try {
      if (ndcValue) {
        let data = { ndc: ndcValue };
        dispatch(getProductDetailByNdc(data)).then((result: any) => {
          setNdcPreviousValue(ndcValue);
          if (result?.payload?.data) {
            setNdcError(false);
            setNdcDetailsData(result?.payload?.data);
            setPackageSize(
              result?.payload?.data?.packageSize &&
                result?.payload?.data?.packageSize > 0
                ? result?.payload?.data?.packageSize
                : 0
            );
            //API call for DEA class
            if (
              result.payload.data.deaClass === 2 ||
              result.payload.data.deaClass === 3 ||
              result.payload.data.deaClass === 5
            ) {
              try {
                dispatch(checkCustomerLicence(bnsAccountId)).then(
                  (result: any) => {
                    if (result?.payload?.data?.isDeaLicense === false) {
                      setErrorNDCMessage(
                        "The DEA license is expired for the selected account"
                      );
                      setDEALicence(result?.payload?.data?.isDeaLicense);
                      props.DEALicence(result?.payload?.data?.isDeaLicense);
                    } else {
                      setDEALicence(result?.payload?.data?.isDeaLicense);
                      props.DEALicence(result?.payload?.data?.isDeaLicense);
                    }
                  }
                );
              } catch (error) {}
            } else {
              setDEALicence(true);
              props.DEALicence(true);
            }
          } else {
            setNdcError(true);
          }
        });
      }
    } catch (error) {}
  };
  const handleChangeRaw = (value: any) => {
    if (value !== undefined) {
      if (moment(value, "MM/YYYY", true).isValid()) {
        setValid(true);
      } else {
        setValid(false);
        setSelectedDate(undefined);
      }
      let date = moment(value);
    } else {
    }
  };

  const radioChange = (e: any, name: any) => {
    setSelectedRadio(name);
    if (name === "NO") {
      setSupplyTypeRadio("BNS Kit");
      updateStartRetrunContextData("BNS Kit");
    }
  };

  const radioChangeSupplyType = (e: any, name: any) => {
    setSupplyTypeRadio(name);
    updateStartRetrunContextData(name);
    if (name === "Print Labels") {
      setShippingLabelQuantity("");
      setErrorShippingLable("");
      setTamperEvidentBagQuantity("");
      setErrorTamperBag("");
    }
  };

  return (
    <>
      <div className="top-controls">
        <Form className="form_box">
          <Row className={`gx-3`}>
            <Col lg={6} xs={12}>
              <Row className={`gx-3 ${props.showProductTour ? "two" : ""}`}>
                <Col xs={12} md={6} xl={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Account <i className="fa fa-asterisk  error-estric"></i>
                    </Form.Label>
                    {disposal_options && (
                      <Select
                        required
                        options={disposal_options}
                        onChange={(e) => bnsAccountChange(e)}
                        isDisabled={
                          tmp !== "start-Inventoried-return" ? true : false
                        }
                        value={
                          bnsAccountId
                            ? {
                                value: bnsAccountId,
                                label: bnsAccountName,
                              }
                            : {}
                        }
                      />
                    )}
                    {errorBNSMessage && (
                      <p className="error-validation">{errorBNSMessage}</p>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} xl={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Entry Mode{" "}
                      <i className="fa fa-asterisk  error-estric"></i>
                    </Form.Label>
                    {isInventory === true
                      ? options_value1 && (
                          <Select
                            options={options_value1}
                            onChange={(e) => {
                              entryModeChange(e);
                            }}
                            isDisabled={
                              !bnsAccountName
                                ? true
                                : tmp !== "start-Inventoried-return"
                                ? true
                                : false
                            }
                            value={entryMode ? options_value1[0] : {}}
                          />
                        )
                      : options_value && (
                          <Select
                            options={options_value}
                            onChange={(e) => {
                              entryModeChange(e);
                            }}
                            isDisabled={
                              !bnsAccountName
                                ? true
                                : tmp !== "start-Inventoried-return"
                                ? true
                                : false
                            }
                            value={
                              entryMode
                                ? entryMode === "1"
                                  ? options_value[0]
                                  : options_value[1]
                                : {}
                            }
                          />
                        )}

                    {errorEntryModeMessage && (
                      <p className="error-validation">
                        {errorEntryModeMessage}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            {entryMode === "1" && !isAccountDisabled && (
              <>
                <Col xs={6} md={3}>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>
                      Do you have Tamper Evident Bags? {"  "}
                    </Form.Label>
                    <div className="mt-2 start-return-option-type">
                      <Form.Check
                        disabled={tmp !== "start-Inventoried-return"}
                        inline
                        type={"radio"}
                        label={"YES"}
                        name="radioGroup"
                        defaultChecked={true}
                        checked={selectedRadio === "YES"}
                        onChange={(e) => radioChange(e, "YES")}
                      />
                      <Form.Check
                        disabled={tmp !== "start-Inventoried-return"}
                        inline
                        type={"radio"}
                        label={"NO"}
                        name="radioGroup"
                        checked={selectedRadio === "NO"}
                        onChange={(e) => radioChange(e, "NO")}
                      />
                      <Form.Check
                        disabled={tmp !== "start-Inventoried-return"}
                        inline
                        type={"radio"}
                        label={"No Controls"}
                        name="radioGroup"
                        checked={selectedRadio === "No Controls"}
                        onChange={(e) => radioChange(e, "No Controls")}
                      />
                    </div>
                  </Form.Group>
                </Col>
                {["YES", "No Controls"].includes(selectedRadio) && (
                  <Col xs={6} md={3}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Supply type {"  "}</Form.Label>
                      <div className="mt-2 start-return-option-type">
                        <Form.Check
                          inline
                          disabled={tmp !== "start-Inventoried-return"}
                          type={"radio"}
                          label={"BNS Kit"}
                          name="radioGroupST"
                          defaultChecked={true}
                          checked={supplyTypeRadio === "BNS Kit"}
                          value="BNS Kit"
                          onChange={(e) => radioChangeSupplyType(e, "BNS Kit")}
                        />
                        <Form.Check
                          inline
                          checked={supplyTypeRadio === "Print Labels"}
                          value="Print Labels"
                          disabled={tmp !== "start-Inventoried-return"}
                          type={"radio"}
                          label={"Print Labels"}
                          name="radioGroupST"
                          onChange={(e) =>
                            radioChangeSupplyType(e, "Print Labels")
                          }
                        />
                      </div>
                    </Form.Group>
                  </Col>
                )}
              </>
            )}
            <Col lg={6} xs={12}>
              <Row className={`gx-3 ${props.showProductTour ? "three" : ""}`}>
                <Col xs={12} md={6} xl={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Qty. of shipping labels{" "}
                      {entryMode === "3" ||
                      (entryMode === "1" &&
                        supplyTypeRadio === "Print Labels") ? (
                        <></>
                      ) : (
                        <i className="fa fa-asterisk  error-estric"></i>
                      )}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Limit of 10"
                      onChange={(e) => {
                        if (Number(e.target.value) > 10) {
                          setErrorShippingLable(
                            "Quantity of Shipping Labels Value should not be greater than 10"
                          );
                        } else {
                          setErrorShippingLable("");
                          setShippingLabelQuantity(e.target.value);
                        }
                      }}
                      min="1"
                      max="10"
                      value={shippingLabelQuantity}
                      disabled={isInputDisabled}
                      onKeyDown={(evt) => {
                        if (
                          evt.key === "e" ||
                          evt.key === "ArrowUp" ||
                          evt.key === "ArrowDown"
                        ) {
                          evt.preventDefault();
                        }
                      }}
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                    {errorShippingLable && (
                      <p className="error-validation">{errorShippingLable}</p>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} xl={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Qty. of tamper-evident bags{" "}
                      {entryMode === "3" ||
                      (entryMode === "1" &&
                        supplyTypeRadio === "Print Labels") ? (
                        <></>
                      ) : (
                        <i className="fa fa-asterisk  error-estric"></i>
                      )}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Limit of 20"
                      onChange={(e) => {
                        if (Number(e.target.value) > 20) {
                          setErrorTamperBag(
                            "Quantity of Tamper Evident Value should not be greater than 20"
                          );
                        } else {
                          setErrorTamperBag("");
                          setTamperEvidentBagQuantity(e.target.value);
                        }
                      }}
                      min="1"
                      onWheel={(e) => e.currentTarget.blur()}
                      max="20"
                      value={tamperEvidentBagQuantity}
                      disabled={isInputDisabled}
                      onKeyDown={(evt) => {
                        if (
                          evt.key === "e" ||
                          evt.key === "ArrowUp" ||
                          evt.key === "ArrowDown"
                        ) {
                          evt.preventDefault();
                        }
                      }}
                    />
                    {errorTamperBag && (
                      <p className="error-validation">{errorTamperBag}</p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
      {props.StatusMode === "Draft" ||
      props.link === "start-Inventoried-return" ? (
        <div className={`${props.showProductTour ? "four" : ""}`}>
          {" "}
          <Form className="form_box form_box_bg form_box_full pb-0 mb-2">
            <Row>
              <Col xs={12} xl={6}>
                <Row className="justify-content-start">
                  <Col xs={12} md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        NDC <i className="fa fa-asterisk  error-estric"></i>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        id="ndc"
                        autoFocus
                        placeholder=""
                        type="number"
                        maxLength={10}
                        onKeyDown={onKeyDownHandler}
                        onChange={(e) => NdcValueChange(e)}
                        onBlur={(e) => NdcValueBlur(e)}
                        min="0"
                        value={ndcValue}
                        onPaste={onNDCPaste}
                        onWheel={(e) => e.currentTarget.blur()}
                      />
                      {errorNDCMessage && (
                        <p className="error-validation">{errorNDCMessage}</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Full Quantity{" "}
                        <i className="fa fa-asterisk  error-estric"></i>
                      </Form.Label>
                      <div className="quantityContainer">
                        <Form.Control
                          type="number"
                          size="sm"
                          placeholder=""
                          disabled={!quantity ? false : true}
                          onKeyDown={(e: any) => {
                            if (
                              e.key === "e" ||
                              e.key === "ArrowUp" ||
                              e.key === "ArrowDown"
                            ) {
                              e.preventDefault();
                            }

                            if (
                              (e.keyCode === 9 && quantityData === "") ||
                              quantityData === "0"
                            ) {
                              setQuantity(true);
                            }
                          }}
                          onChange={(e) => {
                            if (e.target.value.includes(".")) {
                              setErrorQuantityMessage(
                                "Quantity must be a whole number."
                              );
                            } else if (Number(e.target.value) > 9999) {
                              setErrorQuantityMessage(
                                "Quantity allows maximum 4 digit."
                              );
                            } else {
                              setErrorQuantityMessage("");
                            }

                            setNdcDetailsDataQuantityValidation(
                              e.target.value,
                              quantity
                            );
                          }}
                          min="1"
                          value={!quantity ? quantityData : ""}
                          onWheel={(e) => e.currentTarget.blur()}
                        />
                      </div>
                      {!quantity ? (
                        <>
                          {errorQuantityMessage && (
                            <p className="error-validation">
                              {errorQuantityMessage}
                            </p>
                          )}
                          {quantityGreaterMessage && (
                            <p className="error-validation">
                              {quantityGreaterMessage}
                            </p>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Partial Quantity{" "}
                        <i className="fa fa-asterisk  error-estric"></i>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        size="sm"
                        placeholder=""
                        disabled={quantity ? false : true}
                        onKeyDown={(evt) => {
                          if (
                            evt.key === "e" ||
                            evt.key === "ArrowUp" ||
                            evt.key === "ArrowDown"
                          ) {
                            evt.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          if (
                            e.target.value !== "" &&
                            e.target.value !== null &&
                            e.target.value !== undefined
                          ) {
                            setNdcDetailsDataQuantityValidation(
                              e.target.value,
                              quantity
                            );
                            if (
                              ndcDetailsData?.casePack > 1 &&
                              parseInt(e.target.value) >
                                ndcDetailsData?.casePack
                            ) {
                              setErrorQuantityMessage(
                                "Partial Quantity cannot be greater than case pack."
                              );
                            } else if (
                              ndcDetailsData?.casePack === 1 &&
                              parseInt(e.target.value) >
                                ndcDetailsData?.packageSize
                            ) {
                              setErrorQuantityMessage(
                                "Partial Quantity cannot be greater than package size."
                              );
                            } else if (
                              parseInt(e.target.value) <= parseInt(packageSize)
                            ) {
                              if (e.target.value.includes(".")) {
                                setErrorQuantityMessage(
                                  "Quantity must be a whole number."
                                );
                              } else if (Number(e.target.value) > 9999) {
                                setErrorQuantityMessage(
                                  "Quantity allows maximum 4 digit."
                                );
                              } else {
                                setErrorQuantityMessage("");
                              }
                            } else if (
                              e.target.value !== "" ||
                              e.target.value !== null
                            ) {
                              setErrorQuantityMessage("");
                            }
                          } else {
                            setErrorQuantityMessage("");

                            setNdcDetailsDataQuantityValidation("", "");
                          }
                        }}
                        value={quantity ? quantityData : ""}
                        min="1"
                        onWheel={(e) => e.currentTarget.blur()}
                      />
                      {quantity ? (
                        <>
                          {errorQuantityMessage && (
                            <p className="error-validation">
                              {errorQuantityMessage}
                            </p>
                          )}
                          {quantityGreaterMessage && (
                            <p className="error-validation">
                              {quantityGreaterMessage}
                            </p>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        LOT#
                        {entryMode === "2" ? (
                          <i className="fa fa-asterisk  error-estric"></i>
                        ) : (
                          <></>
                        )}
                      </Form.Label>
                      <Form.Control
                        disabled={entryMode === "" ? true : false}
                        size="sm"
                        placeholder=""
                        value={lotNumber}
                        onChange={(e) => {
                          setLotNumber(e.target.value);
                          setErrorLotMessage("");
                        }}
                        min="0"
                      />
                      {errorLotMessage && (
                        <p className="error-validation">{errorLotMessage}</p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} xl={6}>
                <Row className="justify-content-start">
                  <Col xs={12} md={3}>
                    <Form.Group className="mb-3 custom-date-range">
                      <Form.Label>
                        Expiration Date{" "}
                        {entryMode === "2" ? (
                          <i className="fa fa-asterisk  error-estric"></i>
                        ) : (
                          <></>
                        )}
                      </Form.Label>
                      <DatePicker
                        id="expiration-date"
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        disabled={entryMode === "" ? true : false}
                        toggleCalendarOnIconClick
                        closeOnScroll={true}
                        selected={entryMode === "" ? null : selectedDate}
                        onChange={(date: any) => {
                          setErrorDateMessage("");
                          setSelectedDate(date);
                        }}
                        onChangeRaw={(event: any) =>
                          handleChangeRaw(event.target.value)
                        }
                        placeholderText="MM/YYYY"
                        className={"form-control"}
                      />
                      {/* {!valid ? (
                        <p className="error-validation">
                          {'Expiration Date must be in month and year'}
                        </p>
                      ) : <></>
                      } */}
                      {errorDateMessage && (
                        <p className="error-validation">{errorDateMessage}</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>GS1 Serial# </Form.Label>
                      <Form.Control
                        disabled={entryMode === "" ? true : false}
                        type="text"
                        size="sm"
                        placeholder=""
                        id="gs1serial"
                        value={gs1SerialNumber}
                        onChange={(e) => setGs1SerialNumber(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={3} xl="auto">
                    <Form.Group className="mb-3">
                      <Form.Label>FULL/PARTIAL</Form.Label>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        label=""
                        onChange={(e) => switchChange(e)}
                        checked={quantity}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label className="d-block">&nbsp;</Form.Label>
                      <Button
                        variant="primary"
                        size="sm"
                        className="px-4"
                        onClick={(e) => addButtonClick(e)}
                        disabled={
                          !DEALicence ||
                          errorQuantityMessage !== "" ||
                          bnsAccountId === "" ||
                          bnsAccountName === "" ||
                          entryMode === ""
                            ? true
                            : false
                        }
                      >
                        <i className="fa-solid fa-plus me-1"></i>Add
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>

              {ndcError ? (
                <Col xs={12}>
                  <div className="text-danger form-text font-12 mb-3">
                    Entered NDC number is not valid or unavailable, please{" "}
                    <Link
                      to="#"
                      className="text-danger fw-bold"
                      onClick={() => setShow(true)}
                    >
                      click here
                    </Link>{" "}
                    to submit request to our customer support team
                  </div>
                </Col>
              ) : (
                <></>
              )}
              <NdcDetailsModal
                detailModalShow={show}
                handleClose={handleClose}
                handleSave={handleSave}
                errorMessage={bnsSelectionError}
              ></NdcDetailsModal>
              {!ndcError &&
              ndcDetailsData &&
              Object?.keys(ndcDetailsData).length !== 0 ? (
                <NDCDetails
                  ndcDetailsData={ndcDetailsData}
                  quantityData={quantityData}
                  quantity={quantity}
                ></NDCDetails>
              ) : (
                <></>
              )}
              <NewNdcSuccessModal
                show={ndSuccessShow}
                handleClose={ndcSucessClose}
                message1={" Your request has been submitted successfully."}
                message2={"Our customer support team reach out to you shortly."}
              ></NewNdcSuccessModal>
            </Row>
          </Form>
        </div>
      ) : (
        <></>
      )}
    </>
  );
});

export default StartReturnHeader;
