import { Button, Col, Form, Row } from "react-bootstrap";
import { usePermission } from "../../helpers/hooks/usePermissions";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import Modal from "react-bootstrap/esm/Modal";
import DatePickerCmp from "./datePickerCmp";
import { hideLoader, showLoader } from "../../pages/shared/GlobalLoader";
import { toast } from "react-toastify";
import getToken from "../../store/api/api";
import { base64toBlob } from "../../utils/base64toBlob";
import { defaultErrorMessage } from "../../interface/comman.constant";
import axios from "../../services/httpService";
import { postCreateARS } from "../../store/api/apiSlice";
import { useAppDispatch } from "../../store/hooks";
import _ from "lodash";
interface Props {
  startReturn?: boolean;
  qty?: number;
  disposalAccount: any;
  show: boolean;
  handleClose: () => void;
  onError: (errorMessage: string) => void;
}

function UPSLabelswoInventoryPopup({
  startReturn,
  qty = 1,
  disposalAccount,
  show,
  handleClose,
  onError,
}: Props) {
  const [upsPayload, setUpsPayload] = useState({
    disposalAccount: disposalAccount,
    pickupdate: "",
    qty: qty,
  });

  const UPSAPI = axios.UPSAPI || "";
  const API_URL = axios.Api;
  const dispatch = useAppDispatch();
  const formRef = useRef<any>();
  const permissions = usePermission(true);
  const navigate = useNavigate();

  const onYesClick = () => {
    permissions?.["StartAReturn"] &&
      navigate("/services/start-Inventoried-return");
  };

  const onNoClick = () => {
    setShowCalendar(true);
  };

  const [showCalendar, setShowCalendar] = useState(false);

  const [errorQuantityMessage, setErrorQuantityMessage] = useState("");
  const [errorDateMessage, setErrorDateMessage] = useState("");
  const [pickUpDateSet, setPickUpDateSet] = useState(false);

  const maxLabelPrintQuantity = 10;

  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      let upsLabelPayload = {
        customerId: upsPayload.disposalAccount.customerCode.slice(0, 34),
        customerName: upsPayload.disposalAccount.disposalAccountName.slice(
          0,
          34
        ),
        customerCompanyName:
          upsPayload.disposalAccount.shippinG_ADDRESSEE.slice(0, 34),
        phoneNumber: upsPayload.disposalAccount.phone,
        address1: upsPayload.disposalAccount.shippinG_ADDRESS,
        city: upsPayload.disposalAccount.shippinG_CITY,
        state: upsPayload.disposalAccount.shippinG_STATE_ABBREVIATION,
        postalCode: upsPayload.disposalAccount.shippinG_ZIP,
        countryCode: upsPayload.disposalAccount.shippinG_COUNTRY,
        numberOfLabel: upsPayload.qty,
        pickupDate: upsPayload.pickupdate,
      };
      if (
        !upsPayload.pickupdate ||
        upsPayload.pickupdate.toString().trim() === ""
      ) {
        toast.error("Select your Pick-Up date.");
        return;
      }

      if (!upsLabelPayload.address1 || upsLabelPayload.address1.trim() === "") {
        toast.error(
          "No default shipping address is associated to your account. Please contact customer service to set up your shipping address."
        );
        return;
      }

      const azureB2C = localStorage.getItem("AzureB2CImpersonate");
      showLoader();
      await fetch(API_URL + UPSAPI + "Ups/GetLabel", {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken()),
          ...((azureB2C && { UserImpersonated: azureB2C }) || {}),
        },
        body: JSON.stringify(upsLabelPayload),
      })
        .then(async (response: any) => {
          if (response.status === 200) {
            const upsapiresponse = await response.json();
            const upsapiresponse_reponse = upsapiresponse?.response;

            if (upsapiresponse?.trackingnumber?.length === 0) {
              if (upsapiresponse_reponse?.code === "0") {
                upsapiresponse.errors?.length
                  ? toast.error(upsapiresponse.errors[0].message)
                  : toast.error(defaultErrorMessage);
              }
            }

            if (upsapiresponse?.trackingnumber?.length > 0) {
              if (upsapiresponse_reponse?.code === "0") {
                if (upsapiresponse.errors?.length)
                  onError(upsapiresponse.errors[0].message);
              }

              handleClose();

              const b = base64toBlob(upsapiresponse?.image, "application/pdf");
              let url = URL.createObjectURL(b);
              let a = document.createElement("a");
              a.href = url;
              a.target = "_blank";
              a.click();

              const payload = {
                Customerid: disposalAccount.id.toString(),
                TrackingNumber: upsapiresponse.trackingnumber,
              };
              dispatch(postCreateARS(payload)).then((result: any) => {
                const arswitherrors: any = [];
                const obj = result?.payload?.data?.data;
                if (obj) {
                  let objs = JSON.parse(obj);
                  objs.map((ars: any, successStatus: any) => {
                    if (ars.successStatus === "ERROR") {
                      arswitherrors.push(ars);
                    }
                  });

                  if (arswitherrors?.length > 0) {
                    toast.error(
                      "We encountered an issue while generating the UPS label(s). Please contact customer support for assistance." +
                        arswitherrors.join("\n"),
                      {
                        autoClose: false,
                      }
                    );
                  }
                } else {
                  toast.error("Some error occurred while saving data.");
                }
              });
            }
          } else {
            toast.error(defaultErrorMessage);
          }
        })
        .catch((err) => {
          toast.error(defaultErrorMessage);
        })
        .finally(() => {
          hideLoader();
        });
    } catch (error) {
      toast.error(defaultErrorMessage);
    }
  };

  const handleCallback = (childData: any) => {
    setUpsPayload((u) => ({ ...u, pickupdate: childData }));
    setPickUpDateSet(true);
  };

  const quantityChange = (e: any) => {
    let inputValue = e.target.value;

    if (!inputValue) {
      setErrorQuantityMessage("Quantity is required.");
    } else if (inputValue < 1) {
      setErrorQuantityMessage("Quantity should be greater than 0.");
    } else if (inputValue > maxLabelPrintQuantity) {
      setErrorQuantityMessage(
        `Quantity cannot be greater than ${maxLabelPrintQuantity}.`
      );
    } else {
      setErrorQuantityMessage("");
    }

    if (!isNaN(inputValue)) {
      setUpsPayload((u) => ({ ...u, qty: inputValue }));
    }
  };

  const handleValidation = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    let validate = true;

    if (!upsPayload?.qty) {
      setErrorQuantityMessage("Quantity is required.");
      validate = false;
    } else if (upsPayload?.qty < 1) {
      setErrorQuantityMessage("Quantity Value should be greater than 0.");
      validate = false;
    }

    if (!upsPayload?.pickupdate) {
      setErrorDateMessage("Pickup date is required.");
      validate = false;
    }

    if (validate) handleSubmit(e);
  };

  const isPrintDisabled = !pickUpDateSet;

  return (
    <Modal
      show={show}
      centered={true}
      dialogClassName="modal-30w"
      className="custom_modal"
      onHide={handleClose}
    >
      <Modal.Header className="custom_modal_header">
        <Modal.Title className="custom_modal_header_title">
          <div className="custom_modal_header_title_left">Print Labels</div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="custom_modal_body">
        {!startReturn && (
          <div className="d-flex justify-content-center align-items-center gap-2 flex-column py-2">
            <p>
              This label should only be used to send non-control returns.
              Controls will need to be inventoried.
            </p>
            <p>Do you want to return controls?</p>
            <div>
              <Button variant="primary" onClick={() => onYesClick()}>
                Yes
              </Button>
              <Button variant="secondary" onClick={() => onNoClick()}>
                No
              </Button>
            </div>
          </div>
        )}

        {startReturn && (
          <Form
            className="form_box"
            onSubmit={handleSubmit}
            noValidate
            ref={formRef}
          >
            <Row className="gx-3">
              <Col xs={12}>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>
                    Quantity of labels to print{" "}
                    <i className="fa fa-asterisk  error-estric"></i>
                  </Form.Label>
                  <Form.Control
                    required
                    value={upsPayload?.qty}
                    type="number"
                    placeholder="Min. quantity should be 1"
                    onChange={(e) => quantityChange(e)}
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                  />
                  {errorQuantityMessage && (
                    <p className="error-validation">{errorQuantityMessage}</p>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group
                  className="mb-3 custom-date-range"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>
                    Pickup Date <i className="fa fa-asterisk  error-estric"></i>
                  </Form.Label>
                  <DatePickerCmp onChange={handleCallback}></DatePickerCmp>
                  {errorDateMessage && (
                    <p className="error-validation">{errorDateMessage}</p>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className="mt-4 text-center">
                  <Button
                    variant="primary"
                    className="px-3"
                    type="submit"
                    disabled={isPrintDisabled}
                  >
                    PRINT
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        )}

        {showCalendar && !startReturn && (
          <Form
            className="form_box"
            onSubmit={handleValidation}
            noValidate
            ref={formRef}
          >
            <Row className="gx-3">
              <Col xs={7} md={7} xl={7}>
                <Form.Group
                  className="mb-3 custom-date-range"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>
                    Pickup Date <i className="fa fa-asterisk  error-estric"></i>
                  </Form.Label>
                  <DatePickerCmp onChange={handleCallback}></DatePickerCmp>
                  <Form.Control.Feedback type="invalid">
                    Please Select To Date.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={5} md={5} xl={5}>
                <Form.Group className="mt-4 text-left">
                  <Button
                    variant="primary"
                    className="px-3"
                    type="submit"
                    disabled={isPrintDisabled}
                  >
                    Print
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default UPSLabelswoInventoryPopup;
