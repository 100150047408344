import React, { createContext, useState, ReactNode } from "react";

interface StartRetrunContextType {
  supplyTypeSelected: string;
  updateStartRetrunContextData: (newValue: string) => void;
  printLabel: boolean;
}

// Create the context object with a fallback default value
const StartReturnContext = createContext<StartRetrunContextType>({
  supplyTypeSelected: "",
  updateStartRetrunContextData: () => {},
  printLabel: false,
});

interface MyProviderProps {
  children: ReactNode;
}

export const StartRetrunContextProvider: React.FC<MyProviderProps> = ({
  children,
}) => {
  const [supplyTypeSelected, setValue] = useState("");

  const updateStartRetrunContextData = (newValue: string) => setValue(newValue);

  const printLabel = supplyTypeSelected === "Print Labels";

  // Context object to provide
  const contextObject: StartRetrunContextType = {
    supplyTypeSelected,
    updateStartRetrunContextData,
    printLabel,
  };

  return (
    <StartReturnContext.Provider value={contextObject}>
      {children}
    </StartReturnContext.Provider>
  );
};

export { StartReturnContext };
