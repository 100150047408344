import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import { DISABLED_UPS_HOLIDAYS } from "../../interface/comman.constant";
import moment from "moment-timezone";

interface Props {
  onChange: (pickupdate: Date) => void;
}

function DatePickerCmp({ onChange }: Props) {
  var currentDate = new Date();
  const [minDate, setMinDate] = useState(currentDate);
  const [selectedToDate, setSelectedToDate] = useState<Date | null>(null);

  var futureDate = addWeekdaysToDate(currentDate, 59);
  const [maxDate, setMaxDate] = useState(futureDate);

  function addWeekdaysToDate(startDate: any, daysToAdd: any) {
    var currentDate = new Date(startDate);
    var count = 0;
    while (count < daysToAdd) {
      currentDate.setDate(currentDate.getDate() + 1);
      // Check if it's a weekend (Saturday or Sunday)
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        count++;
      }
    }
    return currentDate;
  }

  const getFirstMondayOfSeptember = (year: any) => {
    const date = new Date(year, 8, 1);
    const day = date.getDate();
    const diff = (7 - day + 1) % 7;
    date.setDate(date.getDate() + diff);
    return date;
  };

  const getThirdMondayInJanuary = (year: any) => {
    const date = new Date(year, 0, 1);
    const day = date.getDate();
    const diff = (7 - day + 1) % 7;
    date.setDate(date.getDate() + diff);
    date.setDate(date.getDate() + 14);
    return date;
  };

  const isDateDisabled = (date: any) => {
    const day = date.getDay();
    const today = new Date();
    const year = today.getFullYear();

    //disable today
    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      return true;
    }
    //disable weekends (Saturday and Sunday)
    if (day === 0 || day === 6) return true;

    //disable holidays
    for (let holiday of DISABLED_UPS_HOLIDAYS) {
      if (date.getMonth() === holiday.month && date.getDate() === holiday.day) {
        return true;
      }
    }

    //disable Labor day
    const firstMonthOfSeptember = getFirstMondayOfSeptember(year);
    if (
      date.getMonth() === 8 &&
      date.getDate() === firstMonthOfSeptember.getDate()
    ) {
      return true;
    }

    //Martin Luther King Jr. Day
    const thirdMondayInJanuary = getThirdMondayInJanuary(year);
    if (
      date.getMonth() === 0 &&
      date.getDate() === thirdMondayInJanuary.getDate()
    ) {
      return true;
    }

    return false;
  };

  const handleToDateChange = (e: any) => {
    const timeZone = "UTC";
    const momentDate = moment.tz(e, timeZone);
    setSelectedToDate(momentDate.toDate());
    onChange(momentDate.toDate());
  };

  return (
    <DatePicker
      showIcon
      toggleCalendarOnIconClick
      className="form-control"
      closeOnScroll={true}
      selected={selectedToDate}
      onChange={(date) => handleToDateChange(date)}
      minDate={new Date()}
      maxDate={maxDate}
      filterDate={(date) => !isDateDisabled(date)}
      placeholderText="Select your Pick-Up date"
      onKeyDown={(e) => {
        e.preventDefault();
      }}
    />
  );
}

export default DatePickerCmp;
