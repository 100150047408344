import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import GridTotalCountHeader from "../../../../components/grid/gridTotalCountHeader";
import { useAppDispatch } from "../../../../store/hooks";
import { GetDrugLiquidation } from "../../../../store/api/apiSlice";
import CurrencyConverter from "../../../shared/currencyConverter/CurrencyConverter";
import getFiles from "../../../shared/csvDownload/csvDownload";
import { useNavigate, useParams } from "react-router-dom";
import {
  COLUMN_CONFIG_REPORT_NAMES,
  formatDatePipe,
  formateIds,
  gridAlignment,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import { getTableFields } from "../../../../helpers/getTableFields";
import { useSelector } from "react-redux";
import drugFileDownload from "../../../shared/csvDownload/drugFileDownload";
import { useUserSettings } from "../../../../helpers/hooks/useUserSettings";
import { showConfigColumns } from "../../../../helpers/getSortedVisibleCols";

function DrugLiquidation() {
  const gridRef = useRef<AgGridReact>(null);
  const myRef = useRef<any>();
  const dispatch = useAppDispatch();
  const [gridApi, setGridApi] = useState<any>(null);
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const queryParams: any = useParams();
  const DrugLiquidationRow = localStorage.getItem("DrugLiquidationRow")
    ? JSON.parse(localStorage.getItem("DrugLiquidationRow") as any)
    : {};
  const [partneredSummaries, setPartneredSummaries] = useState<any>(null);

  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalCreditedERV: 0,
    totalOpenERV: 0,
    isForExcel: false,
    sortField: "",
    csvName: "DrugLiquidation.csv",
    URL: "Reports/GetDrugLiquidation",
    filterOptions: [],
    orderNumber: queryParams.id,
  });

  const columnItems = [
    {
      headerName: "Name/Job",
      headerTooltip: "Name/Job",
      field: "nameJob",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Service Date",
      headerTooltip: "Service Date",
      field: "serviceDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: false,
      valueFormatter: function (params: any) {
        return formatDatePipe(params.value);
      },
      comparator: customComparator,
    },
    {
      headerName: "Process Date",
      headerTooltip: "Process Date",
      field: "processDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: false,
      valueFormatter: function (params: any) {
        return formatDatePipe(params.value);
      },
      comparator: customComparator,
    },
    {
      headerName: "Payment Date",
      field: "paymentDate",
      hide: false,
      comparator: customComparator,
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
    }, {
      headerName: "Amount",
      headerTooltip: "Amount",
      tooltipShowDelay: 0,
      field: "amount",
      hide: false,
      comparator: customComparator,
      type: gridAlignment.RIGHT,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    }, {
      headerName: "Reference Number",
      headerTooltip: "Reference Number",
      tooltipShowDelay: 0,
      field: "referenceNumber",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    }
  ];

  const { id, dbColumnConfig } = useUserSettings(COLUMN_CONFIG_REPORT_NAMES?.DrugLiquidation);
  const [colDefs, setColDefs] = useState(() => showConfigColumns(columnItems, dbColumnConfig));

  function customComparator() {
    return 0; //means no comparing and no sorting client side
  }

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        partneredDetails();
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      partneredDetails();
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
    refreshPage,
  ]);

  useEffect(() => {
    if (gridData.isForExcel === true) {
      getFiles(gridData, visibleColumnNames(colDefs), formateIds(userAccounts));
      updateGridData("isForExcel", false);
    }
  }, [gridData.isForExcel]);

  useEffect(() => {
    if (Object.keys(dbColumnConfig).length > 0)
      setColDefs(showConfigColumns(columnItems, dbColumnConfig));
  }, [dbColumnConfig])

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();

    setColDefs(columnValues);
    myRef?.current.handleClose();
  };
  // this function defination change after enterprise
  const partneredDetails = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              pageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              pageSize: gridData.pageSize,
              sortField: gridData.sortField,
              sortDirection: gridData.sortOrder
                ? parseInt(gridData.sortOrder)
                : 1,
              isForExcel: gridData.isForExcel,
              globalSearch: gridData.globalSearch,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
              orderNumber: queryParams.id,
            };
            updateGridData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );
            dispatch(GetDrugLiquidation(data)).then((result: any) => {
              if (result?.payload?.data?.drugLiquidations?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.drugLiquidations,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
              updateGridData("totalRows", result?.payload?.data?.totalCount);

              setPartneredSummaries(result?.payload?.data?.partneredSummaries);
            });
          },
        };
        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) { }
  };

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? 0 : 1);
      updateGridData("sortField", colId);
    }
  };

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };

  const onExport = (e: any) => {
    updateGridData("isForExcel", true);
  };
  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  }
  return (
    <Container fluid>
      <PageTitle
        name={"Drug Liquidation - " + queryParams.id}
        buttonName="Back"
        routePage="/reports/order-history"
      ></PageTitle>

      <div className="drug-liquidation-top">
        <div className="content_body">
          <Row>
            <Col xs={12} md={7}>
              <div className="heading">
                <h2>Drug liquidation statement</h2>
              </div>
              <div className="drug-liquidation-detail">
                <h3>{DrugLiquidationRow?.accountName}</h3>
                <div className="card-gray  mb-3 d-flex justify-content-between flex-wrap">
                  <div className="d-flex flex-column">
                    <label>Account #</label>
                    <span className="value">
                      {DrugLiquidationRow?.customerId}
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <label>Process Date</label>
                    <span className="value">
                      {DrugLiquidationRow?.processedDate}
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <label>Sales Order#</label>
                    <span className="value">
                      {DrugLiquidationRow?.orderNumber}
                    </span>
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={12} md={{ span: 4, offset: 1 }}>
              <div className="pdf-listing-wrapper">
                <div className="pdf-listing">
                  <h3> PDF Reports</h3>
                  <ul>
                    <li
                      className="cursor_pointer"
                      onClick={(e) => {
                        drugFileDownload(
                          "Reports/GetDrugLiquidationEligibleControlFile",
                          "Liquidation_Eligibile_Control.pdf",
                          queryParams.id,
                          formateIds(userAccounts)
                        );
                      }}
                    >
                      <i className="fa fa-file-pdf icon" aria-hidden="true"></i>
                      <a className="me-4">Liquidation Eligible: Controls</a>
                    </li>

                    <li
                      className="cursor_pointer"
                      onClick={(e) => {
                        drugFileDownload(
                          "Reports/GetDrugLiquidationIneligibleControlFile",
                          "Liquidation_Ineligible_Control.pdf",
                          queryParams.id,
                          formateIds(userAccounts)
                        );
                      }}
                    >
                      <i className="fa fa-file-pdf icon" aria-hidden="true"></i>
                      <a className="me-4">Liquidation Ineligible: Controls</a>
                    </li>

                    <li
                      className="cursor_pointer"
                      onClick={(e) => {
                        drugFileDownload(
                          "Reports/GetDrugLiquidationPaymentSummaryControlFile",
                          "Payment_Summary_Control.pdf",
                          queryParams.id,
                          formateIds(userAccounts)
                        );
                      }}
                    >
                      <i className="fa fa-file-pdf icon" aria-hidden="true"></i>
                      <a className="me-4">Payment Summary: Controls</a>
                    </li>

                    <li
                      className="cursor_pointer"
                      onClick={(e) => {
                        drugFileDownload(
                          "Reports/GetDrugLiquidationEligibleNonControlFile",
                          "Liquidation_Eligibile_Non_Control.pdf",
                          queryParams.id,
                          formateIds(userAccounts)
                        );
                      }}
                    >
                      <i className="fa fa-file-pdf icon" aria-hidden="true"></i>
                      <a className="me-4">Liquidation Eligible: Non-Controls</a>
                    </li>

                    <li
                      className="cursor_pointer"
                      onClick={(e) => {
                        drugFileDownload(
                          "Reports/GetDrugLiquidationIneligibleNonControlFile",
                          "Liquidation_Ineligibile_Non_Control.pdf",
                          queryParams.id,
                          formateIds(userAccounts)
                        );
                      }}
                    >
                      <i className="fa fa-file-pdf icon" aria-hidden="true"></i>
                      <a className="me-4">Liquidation Ineligible: Non-Controls</a>
                    </li>

                    <li
                      className="cursor_pointer"
                      onClick={(e) => {
                        drugFileDownload(
                          "Reports/GetDrugLiquidationPaymentSummaryNonControlFile",
                          "Payment_Summary_Non_Control.pdf",
                          queryParams.id,
                          formateIds(userAccounts)
                        );
                      }}
                    >
                      <i className="fa fa-file-pdf icon" aria-hidden="true"></i>
                      <a className="me-4">Payment Summary: Non-Controls</a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="content_body">
        <GridComponent
          dbUserReportId={id}
          dbReportName={COLUMN_CONFIG_REPORT_NAMES?.DrugLiquidation}
          dbColumnConfig={dbColumnConfig}
          defaultColumns={columnItems}
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          onExport={onExport}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
        />
      </div>
    </Container>
  );
}
export default DrugLiquidation;
