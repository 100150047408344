import { useCallback, useEffect, useRef, useState, useContext } from "react";
import { Button, Container } from "react-bootstrap";
import GridClientComponent from "../../../../components/grid/gridClientSide";
import { AgGridReact } from "ag-grid-react";
import StartReturnHeader from "./startReturnHeader";
import ImportInventoryModal from "./importInventoryModal";
import {
  COLUMN_CONFIG_REPORT_NAMES,
  formatDatePipe,
  gridAlignment,
} from "../../../../interface/comman.constant";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../store/hooks";
import { getReturnOrder, putReturnOrder } from "../../../../store/api/apiSlice";
import { toast } from "react-toastify";
import SaveModal from "./saveModal";
import StartReturnDeletePopup from "./startReturnDeletePopup";
import Joyride, { CallBackProps, STATUS } from "react-joyride";
import moment from "moment";
import { useUserSettings } from "../../../../helpers/hooks/useUserSettings";
import { showConfigColumns } from "../../../../helpers/getSortedVisibleCols";
import { cloneDeep } from "lodash";
import { StartRetrunContextProvider } from "../../../../context/StartReturnContext";
import Zendesk, { ZendeskAPI } from "../../../../zendesk/ZendeskConfig";
function formatDateFn(value: any) {
  return value?.data?.expirationDate
    ? moment(value?.data?.expirationDate).format("MM/YYYY")
    : "";
}

export const StartReturn = () => {
  const [showProductTour, setShowProductTour] = useState(false);
  const [joyRideKey, setJoyRideKey] = useState(0);
  const [show, setShow] = useState(false);
  const childRef = useRef<any>(null);
  const myRef = useRef<any>();
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();
  const [totalData, setTotalData]: any = useState();
  const [DeaLicence, setDeaLicence] = useState();
  const [gridApi, setGridApi] = useState<any>(null);
  const [saveModalShow, setSaveModalShow] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteButtonId, setDeleteButtonId] = useState();
  const [startReturnData, setStartReturnData] = useState([]);
  const [importId, setImportId] = useState();
  const [isImported, setIsImported] = useState(false);
  const [statusMode, setStatusMode] = useState();
  const status_id = { Draft: 1, Pending: 2 };
  const [gridData, setGridData] = useState({
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
  });

  const location = useLocation();
  let tmp = location.pathname.slice(
    location.pathname.lastIndexOf("/") + 1,
    location.pathname.length
  );

  const columnItems = [
    {
      headerName: "NDC",
      field: "ndc",
      filter: "agTextColumnFilter",
      menuTabs: ["filterMenuTab"],
      headerTooltip: "NDC",
      tooltipShowDelay: 0,
      hide: false,
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "FULL QTY.",
      field: "quantity",
      filter: "agNumberColumnFilter",
      headerTooltip: "FULL QTY.",
      tooltipShowDelay: 0,
      hide: false,
      valueFormatter: function (params: any) {
        if (params.data.quantityType === 2) {
          return params.value;
        } else {
          return "";
        }
      },
      type: gridAlignment.RIGHT,
      menuTabs: ["filterMenuTab"],
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "PARTIAL QTY.",
      field: "quantity1",
      headerTooltip: "PARTIAL QTY.",
      tooltipShowDelay: 0,
      filter: "agNumberColumnFilter",
      hide: false,
      valueFormatter: function (params: any) {
        if (params.data.quantityType === 1) {
          return params.value;
        } else {
          return "";
        }
      },
      type: gridAlignment.RIGHT,
      menuTabs: ["filterMenuTab"],
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "PRODUCT",
      field: "productName",
      filter: "agTextColumnFilter",
      tooltipField: "productName",
      headerTooltip: "PRODUCT",
      tooltipShowDelay: 0,
      hide: false,
      menuTabs: ["filterMenuTab"],
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "CASE & PKG. SIZE",
      headerTooltip: "CASE & PKG. SIZE",
      tooltipShowDelay: 0,
      field: "caseAndPackageSize",
      filter: "agTextColumnFilter",
      hide: false,
      menuTabs: ["filterMenuTab"],
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "QTY. VALIDATION",
      field: "quantityValidation",
      headerTooltip: "QTY. VALIDATION",
      tooltipShowDelay: 0,
      hide: false,
      filter: "agTextColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "INNER/OUTER NDC",
      headerTooltip: "INNER/OUTER NDC",
      tooltipShowDelay: 0,
      field: "isInnerNdc",
      hide: false,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      cellRenderer: function (params: any) {
        return params.value === true ? (
          <i title="Inner" className="fa-solid fa-circle-info"></i>
        ) : (
          <> </>
        );
      },
    },
    {
      headerName: "DEA CLASS",
      headerTooltip: "DEA CLASS",
      tooltipShowDelay: 0,
      field: "deaClass",
      filter: "agNumberColumnFilter",
      hide: false,
      valueFormatter: function (params: any) {
        if (params.value !== 0) {
          return params.value;
        } else if (params.value === 0) {
          return params.value;
        } else {
          return "";
        }
      },
      menuTabs: ["filterMenuTab"],
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "LOT#",
      headerTooltip: "LOT#",
      tooltipShowDelay: 0,
      field: "lotNumber",
      filter: "agTextColumnFilter",
      hide: false,
      valueFormatter: function (params: any) {
        if (params.value !== "") {
          return params.value;
        } else {
          return "";
        }
      },
      menuTabs: ["filterMenuTab"],
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "EXPIRATION DATE",
      headerTooltip: "EXPIRATION DATE",
      tooltipShowDelay: 0,
      field: "expirationDate",
      hide: false,
      valueGetter: formatDateFn,
      filter: "agTextColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "GS1 SERIAL#",
      headerTooltip: "GS1 SERIAL#",
      tooltipShowDelay: 0,
      field: "gs1SerialNumber",
      filter: "agTextColumnFilter",
      menuTabs: ["filterMenuTab"],
      hide: false,
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "FULL/PARTIAL",
      headerTooltip: "FULL/PARTIAL",
      tooltipShowDelay: 0,
      field: "quantityType",
      suppressMenu: true,
      hide: false,
      floatingFilterComponentParams: { suppressFilterButton: true },
      valueFormatter: function (params: any) {
        if (params.value === 1) {
          return "Partial";
        } else {
          return "Full";
        }
      },
    },
    {
      headerName: "",
      field: "",
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      pinned: statusMode === "Draft" ? "right" : "",
      hide: false,
      minWidth: 40,
      maxWidth: 90,
      flex: 1,
      cellRenderer: function (params: any) {
        return statusMode === "Draft" ? (
          <div className="action-col" onClick={() => deleteButtonClick(params)}>
            <a href="#">
              <i className="fa-solid fa-trash-can"></i>
            </a>
          </div>
        ) : (
          <></>
        );
      },
    },
  ];

  const { id, dbColumnConfig } = useUserSettings(
    COLUMN_CONFIG_REPORT_NAMES?.InventoriedReturn
  );
  const [colDefs, setColDefs] = useState(() =>
    showConfigColumns(columnItems, dbColumnConfig)
  );

  const dispatch = useAppDispatch();

  const handleClose = () => {
    // gridDataResult(totalData);
    setIsImported(true);
    setShow(false);
  };
  const handleShow = (id: any) => {
    setImportId(id);
    setShow(true);
  };
  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const onGridReady = useCallback((params: any) => {
    setGridApi(params.api);
    return updateGridData("rows", gridData.rows);
  }, []);

  const onClickFunction = () => {
    childRef.current?.handleImportInventoryClick();
  };
  const gridDataResult = (data: any) => {
    setStatusMode(data?.status);
    setTotalData(data);
    const dataProducts = data?.products;
    if (dataProducts && dataProducts.length > 0) {
      dataProducts.forEach((element: any) => {
        if (element.quantityType === 1) {
          element["quantity1"] = element.quantity;
          element.quantity = "";
        }
      });
    }
    updateGridData("rows", dataProducts);
    updateGridData("totalRows", data?.products?.length);
  };

  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
  };

  useEffect(() => {
    const productTour = localStorage.getItem("product_tour");
    if (productTour === "true") {
      setJoyRideKey((k) => k + 1);
      setShowProductTour(true);
    }
    if (tmp !== "start-Inventoried-return") {
      try {
        localStorage.removeItem("product_tour");
        let dataProducts: any;
        dispatch(getReturnOrder(tmp)).then((result: any) => {
          dataProducts = result?.payload?.data?.products;
          if (dataProducts && dataProducts.length > 0) {
            dataProducts.forEach((element: any) => {
              if (element.quantityType === 1) {
                element["quantity1"] = element.quantity;
                element.quantity = "";
              }
            });
          }
          setStartReturnData(dataProducts);
          setStatusMode(result?.payload?.data.status);
          updateGridData("rows", dataProducts);
          setTotalData(result?.payload?.data);
        });
      } catch (error) {}
    }
  }, [gridData.isForExcel, isImported, location.state]);

  useEffect(() => {
    if (tmp !== "start-Inventoried-return") {
      let data = paginate(
        cloneDeep(gridData.rows),
        gridData.pageSize,
        gridData.page
      );

      updateGridData("rows", data);
    }
  }, [gridData.page, gridData.pageSize]);

  useEffect(() => {
    setColDefs(columnItems);
  }, [statusMode]);

  useEffect(() => {
    if (Object.keys(dbColumnConfig).length > 0)
      setColDefs(showConfigColumns(columnItems, dbColumnConfig));
  }, [dbColumnConfig]);

  function paginate(array: any, page_size: any, page_number: any) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  function getVisibleColumnKeys() {
    if (gridRef.current && gridRef.current.api) {
      const visibleColumns = gridRef.current.api
        .getColumnState()
        .filter((col) => !col.hide);
      return visibleColumns.map((col) => col.colId);
    }
    return [];
  }
  const onExport = useCallback(() => {
    const visibleColumnKeys = getVisibleColumnKeys();

    gridRef.current!.api.exportDataAsCsv({
      allColumns: false,
      columnKeys: visibleColumnKeys, // Specify which columns to export
      fileName: "Start Inventored Return.csv",
      processCellCallback: (params) => {
        if (params.column.getColId() === "isInnerNdc") {
          return params.value === true ? "Inner" : "Outer";
        } else if (params.column.getColId() === "quantityType") {
          return params.value === 1 ? "Partial" : "Full";
        } else if (params.column.getColId() === "expirationDate") {
          return params.value &&
            params.value !== null &&
            params.value !== undefined
            ? moment(params.value, "MM-DD-YYYY").format("MM/YYYY")
            : "";
        } else {
          return params.value;
        }
      },
    });
  }, []);

  const deleteButtonClick = (params: any) => {
    setIsDeleteModal(true);
    setDeleteButtonId(params.data.id);
  };

  useEffect(() => {  
      const timeout1 = setTimeout(() => {
        ZendeskAPI("webWidget", "close");
        ZendeskAPI("webWidget", "hide");
        
        const timeout2 = setTimeout(() => {
          ZendeskAPI("webWidget", "open");
          ZendeskAPI("webWidget", "show");
          ZendeskAPI("webWidget", "close"); 
        }, 1000);        
        return () => clearTimeout(timeout2);
      }, 1000);  
     
      return () => clearTimeout(timeout1);   

  }, [location]);

  const yesButtonClick = () => {
    const index = totalData?.products?.findIndex(
      (item: any) => item.id === deleteButtonId
    );
    if (index !== -1) {
      const newArray = [
        ...totalData.products?.slice(0, index),
        ...totalData.products?.slice(index + 1),
      ];
      totalData?.products?.forEach((element: any) => {
        if (element["quantity"] === "") {
          element["quantity"] = element["quantity1"];
          delete element["quantity1"];
        }
      });
      totalData["products"] = newArray;
      totalData["statusId"] = status_id.Draft;
      dispatch(putReturnOrder(totalData)).then((result: any) => {
        if (result?.payload?.isSuccess) {
          toast.success("Product deleted successfully");
          gridDataResult(totalData);
          setIsDeleteModal(false);
        }
      });
    }
  };
  const handleJoyrideCallback = (data: CallBackProps) => {
    if (data.action === "close" || data.action === "skip") {
      setShowProductTour(false);
      localStorage.removeItem("product_tour");
    }
  };

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();

    toast.success("Saved Successfully");
    setColDefs(cloneDeep(columnValues));

    myRef?.current.handleClose();

    if (gridRef.current) {
      // gridRef.current.api.autoSizeAllColumns();
    }
  };

  const journeySteps = [
    {
      target: ".one",
      placement: "center" as const,
      content:
        "This page enables users to inventory product and request shipping labels associated to that product. If a return consists solely of non-controlled products, an inventory is not required and can be initiated by requesting Box and Ship supplies under services menu.",
    },
    {
      target: ".two",
      content:
        "Begin by selecting the Entry Mode (Start a Return - No CII's = NDC and Quantity only; Start a Return - Includes CII's enables Lot#/Exp. Date/GS1 Serial Number if desired) as well as the account for which the return is being inventoried.",
    },
    {
      target: ".three",
      content:
        "Next, select the number of shipping labels and tamper-evident bags necessary for the return.",
    },
    {
      target: ".four",
      content:
        "Scan or enter the NDC then hit tab to enter Full Quantity. If entering a Partial Quantity, hit tab again to enable. If in Start a Return - Includes CII's entry mode, enter other relevant details. Lines will be saved to the list below and can be removed in case of an error.",
    },
    {
      target: ".five",
      content:
        "Inventories in CSV/Excel format can be uploaded here as an alternative to manual entry.",
    },
    {
      target: ".six",
      content:
        "Inventory history, including saved drafts inventories, are listed here.",
    },
    ...(totalData && statusMode === "Draft"
      ? [
          {
            target: ".seven",
            content:
              "Once your inventory is complete, click Submit to finalize the inventory.",
          },
        ]
      : []),
  ];

  return (
    <>
      {" "}
      <StartRetrunContextProvider>
        <Container fluid>
          <Joyride
            key={`joyride-${joyRideKey}`}
            run={showProductTour}
            steps={journeySteps}
            continuous={true}
            scrollToFirstStep={true}
            showProgress={true}
            showSkipButton={true}
            callback={handleJoyrideCallback}
          />
          <div className="content_header d-flex justify-content-between align-items-center flex-wrap gap-3 gap-lg-0">
            <div
              className={`header_left flex-shrink-0 ${
                showProductTour ? "one" : ""
              }`}
            >
              <h5
                className="content_header_title"
                title="Start an Inventoried Return"
              >
                Start an Inventoried Return
              </h5>
            </div>
            <div className="header_right">
              {statusMode === "Draft" || statusMode === undefined ? (
                <Button
                  variant="primary"
                  onClick={onClickFunction}
                  className={`d-flex gap-2 align-items-center ${
                    showProductTour ? "five" : ""
                  }`}
                >
                  <i className="fa-solid fa-download d-none d-md-inline-block"></i>{" "}
                  Import Inventory
                </Button>
              ) : (
                <></>
              )}
              <Button
                className={`d-flex gap-2 align-items-center ${
                  showProductTour ? "six" : ""
                }`}
                variant="primary"
                onClick={() => navigate("/services/viewHistory")}
                title="View History"
              >
                <i className="fa-solid fa-clock-rotate-left"></i> View History
              </Button>
            </div>
          </div>

          <div className="content_body start-return">
            <StartReturnHeader
              gridDataResult={(data: any) => gridDataResult(data)}
              DEALicence={(data: any) => setDeaLicence(data)}
              StatusMode={statusMode}
              link={tmp}
              ref={childRef}
              showProductTour={showProductTour}
              handleShow={(data: any) => handleShow(data)}
            ></StartReturnHeader>

            {show ? (
              <ImportInventoryModal
                handleClose={handleClose}
                show={show}
                showProductTour={showProductTour}
                id={importId}
              ></ImportInventoryModal>
            ) : (
              <></>
            )}
            <GridClientComponent
              dbUserReportId={id}
              dbReportName={COLUMN_CONFIG_REPORT_NAMES?.InventoriedReturn}
              dbColumnConfig={dbColumnConfig}
              defaultColumns={columnItems}
              ref={myRef}
              gridRef={gridRef}
              rowData={gridData.rows}
              colDefs={colDefs}
              popupSave={popupSave}
              onGridReady={onGridReady}
              paginationPageSize={gridData.pageSize}
              paginationPageSizeSelector={gridData.rowsPerPageOptions}
              gridData={gridData}
              setGridData={setGridData}
              hideheader={false}
              hidesearch={true}
              onExport={onExport}
              onClearFilter={onClearFilter}
              autoSizeStrategy={{
                type: "fitCellContents",
                colIds: [
                  "id",
                  "ndc",
                  "quantityType",
                  "quantity",
                  "lotNumber",
                  "expirationDate",
                  "gs1SerialNumber",
                  "productName",
                  "deaClass",
                  "caseAndPackageSize",
                  "quantityValidation",
                  "isInnerNdc",
                ],
              }}
            ></GridClientComponent>

            <SaveModal
              show={saveModalShow}
              handleClose={() => setSaveModalShow(false)}
              data={totalData}
              entryMode={totalData?.entryMode}
            ></SaveModal>

            <StartReturnDeletePopup
              show={isDeleteModal}
              handleClose={() => setIsDeleteModal(false)}
              handleYes={() => yesButtonClick()}
            ></StartReturnDeletePopup>
            {totalData && statusMode === "Draft" ? (
              <div className={`d-flex justify-content-end mt-2`}>
                <Button
                  className={`${showProductTour ? "seven" : ""}`}
                  variant="primary"
                  onClick={() => setSaveModalShow(true)}
                >
                  Submit
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => setSaveModalShow(false)}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Container>
      </StartRetrunContextProvider>
    </>
  );
};
export default StartReturn;
