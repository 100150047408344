import React from "react";
import { Button, Modal } from "react-bootstrap";

function PrintLabelMsgModal(props: any) {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      className="custom_modal alert_modal"
      centered={true}
    >
      <Modal.Header className="custom_modal_header pb-0 border-0">
        <Modal.Title className="custom_modal_header_title">
          <div className="custom_modal_header_title_left"></div>
          <div className="custom_modal_header_title_right">
            <Button variant="link" onClick={props.handleClose}>
              <i className="fa-solid fa-times"></i>
            </Button>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom_modal_body">
        <div className="alert_modal_body_wrap">
          <div className="alert_modal_body_inner">
            <div className="alert_modal_body_icon">
              <i className="fa-solid fa-circle-xmark text-danger"></i>
            </div>
            <div className="alert_modal_body_text">
              Pickup not successfully scheduled. Please contact
              UPS(1-800-742-5877) directly to schedule your pickup.
            </div>
            <div className="alert_modal_body_action">
              <Button variant="primary" onClick={props.handleClose}>
                Done
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PrintLabelMsgModal;
